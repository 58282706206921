import { useState } from "react";
import { Family as FamilyType, Record } from "./types";
import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  CheckIcon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import SignatureDialog from "./SignatureDialog";

interface FamilyProps {
  family: FamilyType;
  attendance: Record;
  onEditFamily: () => void;
  onCheckInOut: (
    record: Partial<Record>,
    flags?: { terms: boolean; photos: boolean }
  ) => void;
}

// TODO: Add session control
// TODO: Figure out attendance format
export default function Family(props: FamilyProps) {
  const { family, attendance, onEditFamily, onCheckInOut } = props;

  const [signatureDialogMode, setSignatureDialogMode] = useState<
    "in" | "out" | null
  >(null);

  function showSignatureDialog(mode: "in" | "out") {
    setSignatureDialogMode(mode);
  }

  function handleSignatureDialogSave(
    data: string,
    flags?: { terms: boolean; photos: boolean }
  ) {
    if (signatureDialogMode !== null) {
      onCheckInOut(
        {
          [signatureDialogMode]: { data, timestamp: new Date().toISOString() },
        },
        flags
      );
    }
    setSignatureDialogMode(null);
  }

  function handleSignatureDialogClose() {
    setSignatureDialogMode(null);
  }

  function toggleChildPresent(id: string) {
    const isPresent = isChildPresent(id);
    const ids = attendance.absentChildIds ?? [];
    onCheckInOut({
      absentChildIds: isPresent
        ? [...ids, id]
        : ids.filter((cid) => cid !== id),
    });
  }

  function isChildPresent(id: string) {
    // TODO: Need current session
    return attendance.absentChildIds
      ? !attendance.absentChildIds.includes(id)
      : true;
  }

  const presentChildren = family.children
    .filter((child) => isChildPresent(child.id))
    .map((child) => child.firstName);

  return (
    <>
      <div>
        <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
          <div className="flex flex-wrap items-center">
            <h3 className="text-2xl font-semibold leading-6 text-gray-900">
              {family.familyName}
            </h3>
            <p className="ml-2 mt-1 truncate text-gray-500">Family</p>
          </div>
          <div className="mt-3 sm:ml-4 sm:mt-0">
            <button
              type="button"
              onClick={onEditFamily}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <PencilSquareIcon className="text-gray-900 size-5 mr-2" />
              Edit Family
            </button>
          </div>
        </div>
        <div className="mt-6">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
            {family.children.map((child) => (
              <div
                key={child.id}
                className={`relative flex rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-amber-500 focus-within:ring-offset-2 hover:border-gray-400 ${
                  !isChildPresent(child.id)
                    ? "opacity-50 bg-gray-200 hover:border-gray-300"
                    : ""
                }`}
              >
                <button
                  onClick={() => toggleChildPresent(child.id)}
                  className={`focus:outline-none text-left w-full px-6 py-5 flex items-center justify-between`}
                >
                  <span>
                    <p className="font-medium text-gray-900">
                      {child.firstName}
                    </p>
                    <p className="text-sm text-gray-500">
                      {isChildPresent(child.id) ? "Present" : "Absent"}
                    </p>
                  </span>

                  {isChildPresent(child.id) ? (
                    <CheckIcon className="size-8 text-amber-600" />
                  ) : (
                    <XMarkIcon className="size-8" />
                  )}
                </button>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-10 pt-10 border-t">
            <div className="relative flex h-32 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-amber-500 focus-within:ring-offset-2 hover:border-gray-400">
              <button
                onClick={() => showSignatureDialog("in")}
                className={`focus:outline-none disabled:opacity-50 disabled:bg-gray-200 disabled:hover:border-gray-300 rounded-lg text-left w-full px-6 py-5 flex items-center justify-between ${
                  attendance.in.timestamp != null &&
                  "opacity-50 bg-gray-100 hover:opacity-100"
                }`}
              >
                <span>
                  <p className="font-medium text-gray-900">{`Check${
                    attendance.in.timestamp != null ? "ed" : ""
                  } In`}</p>
                  <p className="text-sm text-gray-500">
                    {presentChildren.join(", ")}
                  </p>
                </span>

                <ArrowDownTrayIcon className="size-8 text-amber-600" />
              </button>
            </div>
            <div className="relative flex h-32 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-amber-500 focus-within:ring-offset-2">
              <button
                disabled={attendance.in.timestamp == null} // TODO: Switch on whether they've checked in
                onClick={() => showSignatureDialog("out")}
                className={`focus:outline-none disabled:opacity-50 disabled:bg-gray-200 disabled:hover:border-gray-300 rounded-lg text-left w-full px-6 py-5 flex items-center justify-between ${
                  attendance.out.timestamp != null &&
                  "opacity-50 bg-gray-100 hover:opacity-100"
                }`}
              >
                <span>
                  <p className="font-medium text-gray-900">{`Check${
                    attendance.out.timestamp != null ? "ed" : ""
                  } Out`}</p>
                  <p className="text-sm text-gray-500">
                    {presentChildren.join(", ")}
                  </p>
                </span>

                <ArrowUpTrayIcon className="size-8 text-amber-600" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <SignatureDialog
        key={signatureDialogMode}
        showTerms={family.terms == null}
        signatureData={
          signatureDialogMode != null
            ? attendance[signatureDialogMode].data
            : null
        }
        isOpen={signatureDialogMode !== null}
        mode={signatureDialogMode || "in"}
        onSave={handleSignatureDialogSave}
        onClose={handleSignatureDialogClose}
      />
    </>
  );
}
