import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import generateId from "./generateId";

interface Child {
  id: string;
  firstName: string;
  lastName: string;
}

interface FamilyDialogProps {
  isOpen: boolean;
  familyName: string;
  familyChildren: Child[];
  onClose: () => void;
  onFamilyNameChange: (name: string) => void;
  onChildAdd: (child: Child) => void;
  onChildRemove: (id: string) => void;
}
export default function FamilyDialog(props: FamilyDialogProps) {
  const {
    isOpen,
    onClose,
    familyName,
    onFamilyNameChange,
    familyChildren,
    onChildAdd,
    onChildRemove,
  } = props;
  const [draftFirstName, setDraftFirstName] = useState("");
  const [draftLastName, setDraftLastName] = useState("");

  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-amber-50 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilSquareIcon
                      className="h-6 w-6 text-aber-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left flex-1">
                    <Dialog.Title
                      as="h2"
                      className="text-lg font-semibold leading-6 text-gray-900 border-b pb-2"
                    >
                      {familyName === ""
                        ? "Add New Family"
                        : `Edit ${familyName} Family`}
                    </Dialog.Title>
                    <div className="mt-6 w-full px-2">
                      <div className="mb-8">
                        <div className="pb-2">
                          <h3 className="text-base font-semibold leading-6 text-gray-900">
                            Family Name
                          </h3>
                        </div>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="familyName"
                            id="familyName"
                            value={familyName}
                            onChange={(ev) =>
                              onFamilyNameChange(ev.target.value)
                            }
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="pb-2">
                          <h3 className="text-base font-semibold leading-6 text-gray-900">
                            Children
                          </h3>
                        </div>
                        <div className="flow-root">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                    >
                                      First Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                      Last Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                                    >
                                      <span className="sr-only">Remove</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white">
                                  {familyChildren.map((child) => (
                                    <tr
                                      key={child.id}
                                      className="even:bg-gray-50"
                                    >
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                        {child.firstName}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {child.lastName}
                                      </td>
                                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                        <button
                                          onClick={() =>
                                            onChildRemove(child.id)
                                          }
                                          className="text-amber-600 hover:text-amber-900"
                                        >
                                          Remove
                                          <span className="sr-only">
                                            , {child.firstName}
                                          </span>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                  <tr className="even:bg-gray-50">
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                      <input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        value={draftFirstName}
                                        onChange={(ev) =>
                                          setDraftFirstName(ev.target.value)
                                        }
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      <input
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        value={draftLastName}
                                        onChange={(ev) =>
                                          setDraftLastName(ev.target.value)
                                        }
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                      <button
                                        onClick={() => {
                                          onChildAdd({
                                            id: generateId(),
                                            firstName: draftFirstName,
                                            lastName: draftLastName,
                                          });
                                          setDraftFirstName("");
                                          setDraftLastName("");
                                        }}
                                        disabled={
                                          draftFirstName.trim() === "" ||
                                          draftLastName.trim() === ""
                                        }
                                        className="text-amber-600 hover:text-amber-900"
                                      >
                                        Add Child
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4 flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mr-3 sm:mt-0 sm:w-auto"
                    onClick={onClose}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
