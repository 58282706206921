import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import utopiaLogo from "./UtopiaOCLogo.png";
import { Attendance, Record, Family as FamilyType } from "./types";
import Family from "./Family";
import { MOCK_SIG } from "./mocks";
import FamilyDialog from "./FamilyDialog";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import generateId from "./generateId";
import AddFamilyDialog from "./AddFamilyDialog";
import seedFamilies from "./seedFamilies";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCVM5XYEHEZxdShmjXnp5JIFxRKXG14PO0",
  authDomain: "utopia-oc-attendance-fd91b.firebaseapp.com",
  projectId: "utopia-oc-attendance-fd91b",
  storageBucket: "utopia-oc-attendance-fd91b.appspot.com",
  messagingSenderId: "969866373133",
  appId: "1:969866373133:web:dcf7ba77bb52896048cd44",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// let seeded = false;

// const fams = seedFamilies();

// async function runSeed(): Promise<void> {
//   if (!seeded) {
//     fams.forEach(async (fam) => {
//       await setDoc(doc(db, "families", fam.id), fam);
//     });
//     seeded = true;
//   }
// }

// // runSeed();

// const families: FamilyType[] = [
//   {
//     id: "1",
//     terms: false,
//     photos: false,
//     familyName: "Jalal",
//     children: [
//       { id: "a", firstName: "Rayyan", lastName: "Jalal" },
//       { id: "b", firstName: "Wasim", lastName: "Jalal" },
//     ],
//   },
//   {
//     id: "2",
//     terms: true,
//     photos: false,
//     familyName: "El-Husayni",
//     children: [
//       { id: "c", firstName: "Hidayah", lastName: "El-Husayni" },
//       { id: "a", firstName: "Huthayfah", lastName: "El-Husayni" },
//     ],
//   },
// ];

// const sessions = ["1/27", "2/3", "2/10"];

// const sessionKeyFormatter = new Intl.DateTimeFormat("en-US", {
//   dateStyle: "short",
// });

// function getCurrentSessionKey() {
//   // Code to check that date and dayOfWeek are valid left as an exercise ;)
//   const now = new Date();
//   const sessionDate = new Date();

//   sessionDate.setDate(now.getDate() + ((13 - now.getDay()) % 7));

//   return sessionKeyFormatter.format(sessionDate);
// }

// const currentSessionKey = getCurrentSessionKey();

// const attendance: Attendance = {
//   [getCurrentSessionKey()]: {
//     "1": { absentChildIds: [], in: MOCK_SIG, out: null },
//   },
// };

// const currentAttendance = attendance[currentSessionKey] || {};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedFamilyId, setSelectedFamilyId] = useState<string | null>(null);
  const [isFamilyDialogOpen, setIsFamilyDialogOpen] = useState(false);
  const [isAddFamilyDialogOpen, setIsAddFamilyDialogOpen] = useState(false);

  const [searchFilter, setSearchFilter] = useState("");
  const [families, setFamilies] = useState<FamilyType[]>([]);
  const [attendance, setAttendance] = useState<{ [id: string]: Record }>({});

  useEffect(() => {
    const familiesQuery = query(collection(db, "families"));
    // TODO: This is hardcoded for second session
    const attendanceQuery = query(
      collection(db, "attendance/2024-03-16/records")
    );

    const unsubFamilies = onSnapshot(familiesQuery, (data) => {
      const updatedFamilies: FamilyType[] = [];
      data.forEach((doc) => updatedFamilies.push(doc.data() as FamilyType));
      setFamilies(updatedFamilies);
    });

    const unsubAttendance = onSnapshot(attendanceQuery, (data) => {
      const updatedAttendance: { [id: string]: Record } = {};
      data.forEach((doc) => (updatedAttendance[doc.id] = doc.data() as Record));
      setAttendance(updatedAttendance);
    });

    return () => {
      unsubFamilies();
      unsubAttendance();
    };
  }, []);

  const selectedFamily = selectedFamilyId
    ? families.find((family) => family.id === selectedFamilyId)
    : null;

  const currentAttendance = selectedFamilyId
    ? attendance[selectedFamilyId]
    : null;

  async function handleCheckInOut(
    record: Partial<Record>,
    flags?: { terms: boolean; photos: boolean }
  ): Promise<void> {
    const attendanceRef = doc(
      db,
      `attendance/2024-03-16/records/${selectedFamilyId}`
    );

    const current = await getDoc(attendanceRef);
    if (current.exists()) {
      setDoc(attendanceRef, record, { merge: true });
    } else {
      setDoc(
        attendanceRef,
        {
          absentChildIds: [],
          in: { data: null, timestamp: null },
          out: { data: null, timestamp: null },
          ...record,
        },
        { merge: true }
      );
    }

    if (flags) {
      const familyRef = doc(db, `families/${selectedFamilyId}`);
      setDoc(familyRef, flags, { merge: true });
    }
  }

  async function addFamily(familyName: string): Promise<void> {
    const familyId = generateId();
    const newFamily: FamilyType = {
      id: familyId,
      familyName,
      children: [],
      photos: null,
      terms: null,
    };
    await setDoc(doc(db, "families", familyId), newFamily);
    setSelectedFamilyId(familyId);
    setIsAddFamilyDialogOpen(false);
    setIsFamilyDialogOpen(true);
  }

  async function handleFamilyUpdate(data: Partial<FamilyType>): Promise<void> {
    const familyRef = doc(db, `families/${selectedFamilyId}`);
    setDoc(familyRef, data, { merge: true });
  }

  const filteredFamilies = families
    .filter((fam) =>
      fam.familyName.toLowerCase().includes(searchFilter.toLowerCase())
    )
    .sort((a, b) => a.familyName.localeCompare(b.familyName));

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-14 w-auto"
                        src={utopiaLogo}
                        alt="Utopia OC"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {filteredFamilies.map((family) => (
                              <li key={family.id}>
                                <button
                                  onClick={() => setSelectedFamilyId(family.id)}
                                  className={classNames(
                                    selectedFamilyId === family.id
                                      ? "bg-gray-50 text-amber-600"
                                      : "text-gray-700 hover:text-amber-600 hover:bg-gray-50",
                                    "group w-full text-left flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <p>
                                    {family.familyName} Family
                                    <small>
                                      <br />(
                                      {family.children
                                        .map((child) => child.firstName)
                                        .join(", ")}
                                      )
                                    </small>
                                  </p>
                                </button>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <button
                            onClick={() => setIsAddFamilyDialogOpen(true)}
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-amber-600"
                          >
                            <PlusIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-amber-600"
                              aria-hidden="true"
                            />
                            Add Family
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 border-r border-gray-200 bg-white pb-4 h-full overflow-hidden">
            <div className="flex h-16 shrink-0 items-center px-6 w-full border-b">
              <img
                className="h-14 w-auto m-auto"
                src={utopiaLogo}
                alt="Utopia OC"
              />
            </div>
            <nav className="flex flex-1 flex-col overflow-auto">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li className="overflow-hidden">
                  <ul className="space-y-1">
                    {filteredFamilies.map((family) => (
                      <li key={family.id}>
                        <button
                          onClick={() => setSelectedFamilyId(family.id)}
                          className={classNames(
                            selectedFamilyId === family.id
                              ? "bg-gray-50 text-amber-600"
                              : "text-gray-700 hover:text-amber-600 hover:bg-gray-50",
                            "group flex flex-col items-center justify-center gap-x-3 rounded-md px-6 leading-6 font-semibold w-full h-24"
                          )}
                        >
                          <p>{family.familyName} Family</p>
                          <small>
                            (
                            {family.children
                              .map((child) => child.firstName)
                              .join(", ")}
                            )
                          </small>
                        </button>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
            <div className="h-20 shrink-0 border-t pt-4">
              <button
                onClick={() => setIsAddFamilyDialogOpen(true)}
                className="group h-14 items-center flex gap-x-3 rounded-md py-2 px-6 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-amber-600 w-full"
              >
                <PlusIcon
                  className="h-6 w-6 shrink-0 text-gray-500 group-hover:text-amber-600"
                  aria-hidden="true"
                />
                Add Family
              </button>
            </div>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-200 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form className="relative flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  type="search"
                  name="search"
                  value={searchFilter}
                  onChange={(ev) => setSearchFilter(ev.target.value)}
                />
              </form>
            </div>
          </div>

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              {selectedFamily != null ? (
                // TODO: Probably shouldn't be using key
                <Family
                  attendance={
                    currentAttendance ?? {
                      absentChildIds: [],
                      in: { data: null, timestamp: null },
                      out: { data: null, timestamp: null },
                    }
                  }
                  family={selectedFamily}
                  onEditFamily={() => setIsFamilyDialogOpen(true)}
                  onCheckInOut={handleCheckInOut}
                />
              ) : (
                <p>Select a family to begin.</p>
              )}
            </div>
          </main>
        </div>
      </div>
      <FamilyDialog
        isOpen={isFamilyDialogOpen}
        onClose={() => setIsFamilyDialogOpen(false)}
        familyName={selectedFamily?.familyName || ""}
        familyChildren={selectedFamily?.children || []}
        onFamilyNameChange={(name: string) =>
          handleFamilyUpdate({ familyName: name })
        }
        onChildAdd={(child) =>
          selectedFamily &&
          handleFamilyUpdate({ children: [...selectedFamily.children, child] })
        }
        onChildRemove={(id) =>
          selectedFamily &&
          handleFamilyUpdate({
            children: selectedFamily.children.filter((c) => c.id !== id),
          })
        }
      />
      <AddFamilyDialog
        isOpen={isAddFamilyDialogOpen}
        onClose={() => setIsAddFamilyDialogOpen(false)}
        onSave={(familyName) => addFamily(familyName)}
      />
    </>
  );
}
