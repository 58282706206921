import { Fragment, useLayoutEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import SignaturePad from "signature_pad";

interface SignatureDialogProps {
  mode: "in" | "out";
  isOpen: boolean;
  onSave: (data: string, flags?: { terms: boolean; photos: boolean }) => void;
  onClose: () => void;
  signatureData: string | null;
  showTerms: boolean;
}

const PIXEL_RATIO = Math.max(window.devicePixelRatio || 1, 1);

export default function SignatureDialog(props: SignatureDialogProps) {
  const { isOpen, mode, onSave, onClose, signatureData, showTerms } = props;
  const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement | null>(null);
  const [isEmpty, setIsEmpty] = useState(true);
  const signatureRef = useRef<SignaturePad | null>(null);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [hasAcceptedPictures, setHasAcceptedPictures] = useState(false);

  function handleCanvasRef(canvas: HTMLCanvasElement | null): void {
    if (canvas) setCanvasRef(canvas);
  }

  useLayoutEffect(() => {
    if (canvasRef) {
      canvasRef.width = canvasRef.offsetWidth * PIXEL_RATIO;
      canvasRef.height = canvasRef.offsetHeight * PIXEL_RATIO;
      canvasRef.getContext("2d")?.scale(PIXEL_RATIO, PIXEL_RATIO);
      signatureRef.current = new SignaturePad(canvasRef);
      signatureRef.current.clear(); // otherwise isEmpty() might return incorrect value
      signatureRef.current.addEventListener("beginStroke", () =>
        setIsEmpty(false)
      );
      if (signatureData) {
        signatureRef.current.fromDataURL(signatureData);
        setIsEmpty(false);
      }
    }
  }, [canvasRef, signatureData]);

  function handleSignatureClear(): void {
    if (signatureRef.current) {
      signatureRef.current.clear();
      setIsEmpty(true);
    }
  }

  function handleSave(): void {
    if (!signatureRef.current) return;
    const data = signatureRef.current.toDataURL();
    onSave(
      data,
      showTerms
        ? { terms: hasAcceptedTerms, photos: hasAcceptedPictures }
        : undefined
    );
  }

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex flex-col transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl h-full sm:h-[800px]">
                <div className="bg-white flex flex-1 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex flex-1 sm:items-start">
                    <div className="mx-auto flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-amber-50 sm:mx-0 sm:h-10 sm:w-10">
                      <PencilIcon
                        className="h-6 w-6 text-amber-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 relative w-full h-full flex flex-col text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="flex-1 h-full w-full">
                        <canvas
                          style={{ height: "100%", width: "100%" }}
                          className="border rounded-lg"
                          ref={(canvas) => handleCanvasRef(canvas)}
                        />
                      </div>
                      <button
                        onClick={handleSignatureClear}
                        className="absolute top-0 right-0 flex items-center h-12 p-2 mt-2 mr-2 rounded-md text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        <XMarkIcon className="size-5 mr-2" /> Clear
                      </button>
                    </div>
                  </div>
                </div>
                {showTerms && (
                  <div className="flex-shrink-0 px-2 py-6 mt-4 sm:px-6 sm:py-3">
                    <fieldset className="sm:ml-12">
                      <legend className="sr-only">Terms</legend>
                      <div className="space-y-5">
                        <div className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              id="terms"
                              aria-describedby="terms-description"
                              name="terms"
                              type="checkbox"
                              required
                              checked={hasAcceptedTerms}
                              onChange={(ev) =>
                                setHasAcceptedTerms(ev.target.checked)
                              }
                              className="h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-600"
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label
                              htmlFor="terms"
                              className="font-medium text-gray-900"
                            >
                              I accept the terms and conditions *
                            </label>
                            <p
                              id="terms-description"
                              className="text-gray-500 sm:text-base text-xs"
                            >
                              I hereby release Utopia OC, its board, volunteers
                              and clients from any and all claims, demands,
                              rights, or causes of action and I hereby agree to
                              indemnify and hold them harmless from any and all
                              liability hereafter arising on account of injury,
                              resulting from the negligence of any person, or
                              otherwise during my child participation in the
                              program and activities and while my child is on
                              and/or in Utopia OC property.
                            </p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              id="photos"
                              aria-describedby="photos-description"
                              name="photos"
                              type="checkbox"
                              checked={hasAcceptedPictures}
                              onChange={(ev) =>
                                setHasAcceptedPictures(ev.target.checked)
                              }
                              className="h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-600"
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label
                              htmlFor="photos"
                              className="font-medium text-gray-900"
                            >
                              I allow photos to be taken
                            </label>
                            <p
                              id="photos-description"
                              className="text-gray-500 sm:text-base text-xs"
                            >
                              I grant Utopia OC, it's staff, and designers the
                              right to capture my child's image and likeness in
                              photographs and videos. I acknowledge that these
                              media items may be used on the Utopia OC social
                              media pages, or website.
                            </p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                )}
                <div className="bg-gray-50 flex-shrink-0 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    disabled={isEmpty || (showTerms && !hasAcceptedTerms)}
                    className="inline-flex w-full justify-center rounded-md bg-amber-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 sm:ml-3 sm:w-40 disabled:opacity-50 disabled:hover:bg-amber-600 disabled:cursor-not-allowed"
                    onClick={handleSave}
                  >
                    {mode === "in" ? "Check In" : "Check Out"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-40"
                    onClick={() => onClose()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
